
.content1{
    border:none; background:white; 
    /* height:210px; */
    padding-top:4%;
    width:100%;
  }
  .img1{
    height:84%; margin-left:15px;
    width:100%;
  }
  .ptag{
     color:#03225C;
  }
  .col-xs-6
  {
    width:50%;
    height:fit-content;
  }
  @media (max-width:700px){
  .col-xs-6{
    width:85%
  
  }
  .heading{
    margin-top: 4% !important;
  }}
  .container1{
  margin-left: 10%;
  }
  .heading{
    margin-top: 30%;
  }