.opacity
{
  opacity:0.2;
 background-color:black;
 height: 100%;
}
/* .carousel-caption{
  padding-bottom: 110px !important;
} */
.carousel-inner{
  background-color: black;
}
/* @media screen and (max-width:989px){
  h3{
    margin-bottom: -27px;
  }
  .carousel-item{
    
  }
} */
@media screen and (max-width:669px){
  .carousel-inner{
    height: 483px  !important;
  }
  .carousel-item{
    height: 100%;
  }
  .w-100{
    height: 100%;
  }
  .d-block{
    height: 100%;
  }
}

