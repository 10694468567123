/* .hoverin:hover .hoverInr{
    width: 40% !important;
    height: 53% !important;
    transition: 0.2s;
}
.hoverin:hover .fa-leaf{
    transition: 0.2s;
    font-size: 3em !important;
   padding: 23px !important;
}
.hoverin:hover .fa-crosshairs{
    transition: 0.2s;
    font-size: 3em !important;
} */
.textSpan{
    font-weight: 500;
    color: white;
    font-size: 1.75rem;
    position: absolute;
    bottom: 39%;
    right: 33px;
    position: inherit;
    text-align: initial;
    padding-left: 10%;

    /* width: min-content;
    min-width: fit-content; */

}