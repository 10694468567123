@media screen and (min-width: 990px) {
  #show7,#show8{
    margin-left: 249px;
  }
  #show4{
    /* margin-left: 250px !important; */
  }
  .positionAdjust{
    margin-left:249px !important ;
  }
  @media not all and (min-resolution:.001dpcm) {
     @supports (-webkit-appearance:none) and (stroke-color:transparent) {
     .positionAdjust {
        margin-top: -40px; 
      } 
    } 
  }
    .dropdown1:hover .dropdown-menu1 {
      display: block!important; 
      margin-top: -7px;

  }
  .dropdown-menu1{
    width: 252px;;
  }  
  .dropdown2:hover .dropdown-menu2 {
    display: block!important;
  }
 
  .dropdown3:hover .dropdown-menu3 {
    display: block!important;
  }
 
  .dropdown4:hover .dropdown-menu4 {
    display: block!important;
  }
  .showNone{
    display: none !important;
  }
  .dropdown-submenu {
    position: relative;
    /* padding-right: 70px; */

  }
  
  .dropdown-submenu a::after {
    transform: rotate(-90deg);
    float:right;
    margin-top: 10px;
  }
  .dropdown-submenu .dropdown-menu {
    top: 0;
    left: 100%;
    margin-left: 180px;
    margin-right: .1rem;
  }
}
  .navbar{
    text-align: center;
    text-decoration-style:dotted !important;
  }
  .dropdown-submenu a::after {
   
    float:right;
    margin-top: 10px;
  }
  .dropdown-item{
    padding-right: 6px;
  }

  @media screen and (max-width: 990px) {
    .ml-5{
      margin-left:0px !important ;    }
  }
  /* .hover-color:hover{
   background-color:green;
    color:white;
  } */
  .block{
    display: block !important;
  }
  @media screen and (min-width:990px) and (max-width:1146px ){
    #show5{
      margin-left: 0px;
      margin-top: 30px;
      width: 185px;
    }
    .toggle1::after{
    transform: none !important;
    display: inline-block;
    margin-left: .255em;
    vertical-align: .255em;
    content: "";
    border-top: .3em solid;
    border-right: .3em solid transparent;
    border-bottom: 0;
    border-left: .3em solid transparent;
  }
}
