.fab{
    color:white;

}
.container{
    padding: 40px 0;
}
.footer{
    background:gray;
    height:auto;
    width:100%;
    color:white;

}
*{
    margin:0;
    padding:0;
    box-sizing: border-box;
}
.footer-content{
    display:flex;
    align-items: center;
    justify-content:center;
    flex-direction: column;
    text-align: center;
}
.footer-content h3{
    font-size: 1.8rem;
    font-weight:400;
    text-transform: capitalize;
    line-height: 3rem;
}
.footer-content p{
    max-width:500px;
    margin:10px auto;
    line-height: 28px;
    font-size:14px;
}
.socials{
    list-style: none;
    display:flex;
    align-items: center;
    justify-content: center;
    margin:1rem 0 1rem 0;

}
.socials li{
    margin:0 10px;

}
.socials a{
    text-decoration: none;
    color:white;

}
.socials a i{
 font-size: 1.7rem;
 transition:color .4s ease;
}
.socials a:hover i{
    color:blue;
}
 .footer-bottom{
background:white;
width: 100%;;
padding:20px 0;
text-align: center;
} 
.footer-bottom p{
    font-size: 14px;
    word-spacing: 2px;
    text-transform:capitalize;
    color:black;

}
.footer-bottom span{
    text-transform: uppercase;
    opacity:.4;
    font-weight: 200;


}
td{
   
    padding: 0px 5px;
    line-height: 1.42857143;
    vertical-align: top;
    font-size: 14px;

}

@media screen and  (min-width:573px){
 .paddTop{
     margin-top: 27px;

 }
}
@media screen and (max-width:572.9px){
 .footer{
     max-width: 100%;
    }
    .container{
        padding: 10px;
    }
}