/* .img-wrapper{
    overflow:hidden;
  }
  img.hover-zoom:hover {
    transform: scale(1.25);
  } */
  /* .img-wrapper {
    overflow: hidden;
  }
  
  img.hover-zoom {
    transition: all 0.3s ease 0s;
    width: 100%;
  }
  img.hover-zoom:hover {
    transform: scale(1.25);
  } */
  .width-25{
    width: 25%;
    min-width: 100px;
  }
  .full-width{
    width:100%;
  }
  .side-img{
    border: solid;
    margin: 13%;
    margin-bottom: 0;
    max-width: 100%;
    width: 72%;
    overflow: visible;
  }
  .width-30{
    width: 40%;
    min-width: 200px;
  }
  figure {
    width: 600px;
    background-repeat: no-repeat;
  }
  
  figure:hover img {
    opacity: 0;
  }
  
  .imggg {
    cursor: zoom-in;
    display: block;
    width: 100%;
    pointer-events: none;
  }
  .sapan-style{
    display: flex;
    /* padding-top: 25%; */
    text-align: initial;
    padding-left: 5%;
    /* font-size: x-large;
    color: gray;
    font-family: serif; */
    flex-direction: column;
  }
  .lastDes{

  }
  @media screen and (max-width: 577px) {
    .lastDes{
      width: auto !important;
    }
  }