.card{
    widows: 100%;
    height:200px;
    
    margin:0 20px;
    font-size:30px;
    margin:0 20px;
    display:flex;
    justify-content: center;
    align-items: center;
  
  }