
.content1{
    border:none; background:white;
     /* height:210px; */
     width:100%;
  }
  .img1{
    height:84%; margin-left:15px;
    width:100%;
  }
  .ptag{
     color:#03225C;
  }
  .col-xs-6
  {
    width:50%
  }
  @media (max-width:700px){
  .col-xs-6{
    width:85%
  
  }
  .heading{
    margin-top: 0% !important;
  }}
  .container1{
  margin-left: 10%;
  
  }
  .heading{
    margin-top: 30%;
  }
  .navigation{
    background-color:gray;
    width:100%;
    height:auto;
  }
  li{
    display:inline;
  }
  ul{
    color:white;
    padding-left:8%;
    text-align: left;
  }
  .container{
    padding-top: 14px;
  }
  .center{
    padding-left: 123px;
  }