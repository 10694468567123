
.content1{
 
    border:none; background:white;
     /* height:210px; */
     width:100%;
  }
  .img1{
    height:84%; margin-left:15px;
    width:100%;
  }
  .ptag{
     color:#03225C;
  }
  .col-xs-6
  {
    width:50%
  }
  @media (max-width:700px){
  .col-xs-6{
    width:85%
  
  }
  .heading{
    margin-top: 0% !important;
  }}
  .container1{
  margin-left: 10%;
  
  }
  .heading{
    margin-top: 30%;
  }
  .navigation{
    background-color:gray;
    width:100%;
    height:auto;
  }
  li{
    display:inline;
  }
  ul{
    color:white;
    padding-left:0%;
  }
  .container{
    padding-top: 14px;
  }
  .center{
    padding-left: 123px;
  }
  .wrapper:hover .fa-stack{
    color: red;
    transition: 0.9s;
    transform: rotateY(180deg);
  }
  .wrapper{
    display: inline-block;
    width: 100%;
    /* border: 1px solid red; */
    overflow: hidden;
    text-align: center;
  }
  .fa-circle{
    color:gray;
  }
  .col-sm-4{
    padding-top:30px
  
  }
  p{
    padding-top:25px;
  }
  .map-container{
    width:100%;
  }
  iframe{
    margin: 50px;
    border-radius: 6px;
    width:100%;
  }